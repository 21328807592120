import React from 'react';
import styled from 'styled-components';
import H2 from './../H2';
import H3 from './../H3';
import P from './../P';

const TextBlock = styled.div`
  max-width: ${props => props.maxWidth};
  text-align: center;
  margin: 0 auto;
  padding: 48px 10px;

  @media (min-width: 640px) {
    padding: 58px 20px 48px;
    &:last-child {
      padding: 58px 20px;
    }
  }
  @media (min-width: 960px) {
    padding: 74px 20px 48px;
    &:last-child {
      padding: 74px 20px;
    }
  }
  @media (min-width: 1200px) {
    padding: 112px 20px 48px;
    h2, div, p {
      max-width: 1140px;
      margin: 0 auto;
    }
    &:last-child {
      padding: 112px 20px;
    }
  }
`;
const ImgDesktop = styled.img`
  display: none;
  @media (min-width: 960px) {
    display: inline;
    margin-bottom: 82px;
  }
`;
const ImgMobile = styled.img`
  margin-bottom: 40px;
  @media (min-width: 640px) {
    margin-bottom: 64px;
  }
  @media (min-width: 960px) {
    display: none;
  }
`;

export default ({title, copy, imgMobile, imgDesktop, maxWidth = '1400px'}) => (
  <TextBlock maxWidth={maxWidth}>
    <H2>{title}</H2>
    <ImgMobile src={imgMobile} />
    <ImgDesktop src={imgDesktop} />
    <P color="#4b4b4b" dangerouslySetInnerHTML={{__html: copy}} />
  </TextBlock>
);
