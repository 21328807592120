import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Hero from "./../components/Hero";
import H2 from "./../components/H2";
import TextSlider from "./../components/Home/TextSlider";
import Content from "./../components/Content";
import TextBlock from "./../components/TextBlock";
import Bullets from "./../components/EAN/Bullets";

import b1 from "./../components/EAN/broadband-bulleticon1.png";
import b2 from "./../components/EAN/broadband-bulleticon2.png";
import b3 from "./../components/EAN/broadband-bulleticon3.png";
import b4 from "./../components/EAN/broadband-bulleticon4.png";
import b5 from "./../components/EAN/broadband-bulleticon5.png";
import b6 from "./../components/EAN/broadband-bulleticon6.png";
import Advantages from "../components/EAN/Advantages";
import LTE from "../components/EAN/LTE";
import ReadyForTakeOff from "../components/EAN/ReadyForTakeOff";

import Layout from "./../components/Layout";

const Page = styled.div``;

const EanLogo = styled.img`
  display: block;
  margin: 0 auto;
`;

const Txt = styled(TextBlock)`
  h3 {
    color: #4b4b4b !important;
  }
`;

const LTEWrap = styled(TextBlock)`
  div h3 {
    color: #fff;
    font-weight: 400;
    font-size: 24px;
  }
  @media (min-width: 640px) {
    font-size: 30px;
  }
`;

const Gallery = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const A = styled.a`
  position: relative;
  display: block;
  padding: 10px;
  flex: 1;
  &:hover {
    > div {
      opacity: 0.8;
    }
  }
`;
const Image = styled(Img)`
  position: relative;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  opacity: 0;
  transition: 0.5s ease;
  background-color: #e20074;
`;

const OverlayText = styled.div`
  color: white;
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TitleWrap = styled.div`
  text-align: center;
  padding-top: 66px;
  @media min-width(960px) {
    padding-top: 106px;
  }
`;

const TextSliderWrap = styled(TextSlider)`
  .text-gallery {
    padding-top: 0;
  }
  h3 {
    font-weight: 400;
    font-size: 22px;
    @media (min-width: 640px) {
      font-size: 30px;
    }
    margin-bottom: 1em;
  }
`;

export default ({ data, location }) => {
  if (!data) {
    return <p />;
  }

  console.log(data);
  const { datoCmsEan: content, slider, lte } = data;

  return (
    <Layout location={location}>
      <Page>
        <Hero src={content.headerImage.url} />
        <TitleWrap>
          <H2>EUROPEAN AVIATION NETWORK</H2>
        </TitleWrap>
        <TextSliderWrap content={slider.edges} />
        <Content bg="#e20074" style={{ paddingBottom: 48 }}>
          <TextBlock
            color="magenta"
            maxWidth="1140px"
            title={content.broadband[0].title}
            subline={content.broadband[0].subline}
          />
          <Bullets content={content.broadband[0]} />
        </Content>

        <Content bg="#ededed">
          <Advantages
            title={content.advantagesTitle}
            intro={content.advantagesIntroCopy}
            advTitle1={content.advantagesTitle1}
            adv1={content.advantages}
            advTitle2={content.advantagesTitle2}
            adv2={content.advantages2}
          />
        </Content>
        <Content bg="#fff">
          <ReadyForTakeOff
            title={content.readyForTakeOffTitle}
            copy={content.readyForTakeOffCopyNode.childMarkdownRemark.html}
            imgMobile={content.readyForTakeOffImageMobile.url}
            imgDesktop={content.readyForTakeOffDesktop.url}
          />
        </Content>

        <Content bg="#ededed" style={{ paddingBottom: 48 }}>
          <Txt
            color="regular"
            maxWidth="1140px"
            title={content.mediaTitle}
            copy={content.mediaCopyNode.childMarkdownRemark.html}
          />
          <Gallery>
            {content.mediaGallery.map((i, k) => (
              <A href={i.alt} key={k} target="_blank">
                <Img fluid={i.fluid} />
                <ImageOverlay>
                  <OverlayText>{i.title}</OverlayText>
                </ImageOverlay>
              </A>
            ))}
          </Gallery>
        </Content>

        <Content bg="#e20074" style={{ paddingBottom: 48 }}>
          <LTEWrap
            color="magents"
            maxWidth="1140px"
            title={content.lteTitle}
            copy={content.lteIntroNode.childMarkdownRemark.html}
          />
          <LTE content={lte} />
        </Content>

        <Content bg="#fff" style={{ paddingBottom: 48, paddingTop: 48 }}>
          <EanLogo src={content.collabEanLogo.url} />
          <TextBlock
            color="regular"
            maxWidth="1140px"
            title={content.collabTitle}
            copy={content.collabCopyNode.childMarkdownRemark.html}
          />
        </Content>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query EANQuery {
    datoCmsEan {
      headerImage {
        url
      }
      broadband {
        title
        subline
        bullet1
        bullet2
        bullet3
        bullet4
        bullet5
        bullet6
      }
      advantagesTitle
      advantagesIntroCopy
      advantagesTitle1
      advantages {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      advantagesTitle2
      advantages2 {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      readyForTakeOffTitle
      readyForTakeOffCopyNode {
        childMarkdownRemark {
          html
        }
      }
      readyForTakeOffImageMobile {
        url
      }
      readyForTakeOffDesktop {
        url
      }
      collabEanLogo {
        url
      }
      collabTitle
      collabCopyNode {
        childMarkdownRemark {
          html
        }
      }
      mediaTitle
      mediaSubline
      mediaCopyNode {
        childMarkdownRemark {
          html
        }
      }
      mediaGallery {
        url
        alt
        title
        fluid(maxWidth: 442, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      lteTitle
      lteIntroNode {
        childMarkdownRemark {
          html
        }
      }
    }
    lte: allDatoCmsLteBox {
      edges {
        node {
          title
          copyNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
          }
        }
      }
    }
    slider: allDatoCmsTextSliderEan {
      edges {
        node {
          subline: headline
          body
        }
      }
    }
  }
`;
