import React from 'react';
import styled from 'styled-components';
import H2 from './../H2';
import H3 from './../H3';

const Wrapper = styled.div`
  text-align: center;
  max-width: 1140px;

  padding: 48px 10px;

  h3 {
    margin-top: 3em;
    margin-bottom: 2em;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @media (min-width: 640px) {
    padding: 58px 20px 48px;
    &:last-child {
      padding: 58px 20px;
    }
  }
  @media (min-width: 960px) {
    padding: 74px 20px 48px;
    &:last-child {
      padding: 74px 20px;
    }
  }
  @media (min-width: 1200px) {
    padding: 112px 20px 48px;
    margin: 0 auto;
    max-width: 1378px;
    h2,
    .intro {
      max-width: 1140px;
      margin: 0 auto;
    }
    &:last-child {
      padding: 112px 20px;
    }
  }
`;

const AdvantagesListsWrapper = styled.div`
  width: 100%;
  @media (min-width: 1140px) {
    display: flex;
  }
`;
const AdvantagesList = styled.div`
  flex: 1;
  h3 {
    color: #e20074;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    @media (min-width: 640px) {
      font-size: 30px;
    }
    @media (min-width: 1140px) {
      width: 50%;
      font-size: 20px;
    }
  }
  &:last-child dl dd svg {
    display: none;
  }
  @media (min-width: 1140px) {
    &:last-child {
      dl dt {
        display: none;
      }
      dl dd {
        flex: 0 100%;
        position: relative;
        margin-left: 40px;
        svg {
          display: inline;
          position: absolute;
          left: -41px;
          top: 0;
        }
      }
    }
  }
`;
const Dl = styled.dl`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > dt,
  > dd {
    height: 110px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: left;
  }
  @media (min-width: 640px) {
    > dt,
    > dd {
      height: 150px;
    }
  }
  @media (min-width: 1140px) {
    > dt,
    > dd {
      height: 80px;
    }
  }
`;
const Dt = styled.dt`
  flex: 0 30%;
  margin: 0;
  background: #e20074;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 13px;
  padding: 0 0 0 10px;
  position: relative;
  svg {
    position: absolute;
    height: 110px;
    top: 0;
    right: -32px;
  }
  @media (min-width: 400px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 25px;
    svg {
      height: 150px;
      right: -37px;
    }
  }
  @media (min-width: 1140px) {
    font-size: 20px;
    svg {
      height: 80px;
      right: -28px;
    }
  }
`;
const Dd = styled.dd`
  flex: 0 calc(70% - 26px);
  width: calc(70% - 26px);
  background: white;
  font-size: 14px;
  padding: 0 10px 0 0;
  margin: 0 0 0 26px;
  ul {
    padding-left: 26px;
    list-style: none;
    position: relative;
    li {
      position: relative;
    }
    li::before {
      content: '.';
      display: inline-block;
      width: 1em;
      margin-left: -0.7em;
      position: absolute;
      top: -4px;
    }
  }
  @media (min-width: 640px) {
    font-size: 15px;
  }
  @media (min-width: 640px) {
    font-size: 20px;
    flex: 0 calc(70% - 36px);
    width: calc(70% - 36px);
    margin: 0 0 0 36px;
  }
  @media (min-width: 1140px) {
    font-size: 16px;
    flex: 0 calc(70% - 18px);
    width: calc(70% - 30px);
    margin: 0 0 0 18px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export default ({title, intro, advTitle1, adv1, advTitle2, adv2}) => (
  <Wrapper>
    <H2>{title}</H2>
    <div className="intro" dangerouslySetInnerHTML={{__html: intro}} />

    <AdvantagesListsWrapper>
      <AdvantagesList>
        <H3>{advTitle1}</H3>
        <Dl>
          {adv1.reduce((acc, a, k) => {
            const desc = a.descriptionNode.childMarkdownRemark.html;
            return acc.concat([
              <Dt key={`dt${k}`}>
                {a.title}
                <MiddleMobile />
              </Dt>,
              <Dd key={`dd${k}`}>
                <div dangerouslySetInnerHTML={{__html: desc}} />
              </Dd>,
            ]);
          }, [])}
        </Dl>
      </AdvantagesList>
      <AdvantagesList>
        <H3>{advTitle2}</H3>
        <Dl>
          {adv2.reduce((acc, a, k) => {
            const desc = a.descriptionNode.childMarkdownRemark.html;
            return acc.concat([
              <Dt key={`dt${k}`}>
                {a.title}
                <MiddleMobile />
              </Dt>,
              <Dd key={`dd${k}`}>
                <MiddleDesktop />
                <div dangerouslySetInnerHTML={{__html: desc}} />
              </Dd>,
            ]);
          }, [])}
        </Dl>
      </AdvantagesList>
    </AdvantagesListsWrapper>
  </Wrapper>
);

const MiddleMobile = () => (
  <svg
    width="37px"
    height="150px"
    className="middleMobile"
    viewBox="0 0 37 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Asset-1" fillRule="nonzero">
        <polyline id="Shape" fill="#E20074" points="0 0 26 75 0 150" />
        <polyline
          id="Shape"
          fill="#FFFFFF"
          points="37 150 9 150 35 75 9 0 37 0"
        />
      </g>
    </g>
  </svg>
);

const MiddleDesktop = () => (
  <svg
    width="41px"
    height="80px"
    viewBox="0 0 41 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="22Asset-1" fill="#FFFFFF" fillRule="nonzero">
        <polyline id="Shape" points="0 0 30 40 0 80" />
        <polyline id="Shape" points="41 80 10 80 40 40 10 0 41 0" />
      </g>
    </g>
  </svg>
);
