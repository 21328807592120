import React from 'react';
import styled from 'styled-components';
import H2 from './../H2';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1140px;
  margin: 0 auto;
`;
const Image = styled.div`
  background-image: url('${props => props.src}');
  padding-top: 56%;
  background-repeat: no-repeat;
  background-size: 100% auto;
`;
const Box = styled.div`
  background: white;
  display: flex;
  margin: 12px;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-basis: calc(50% - 24px);
  }
`;

const H3 = styled.div`
  color: #e20074;
  font-weight: 900;
  text-transform: uppercase;
  padding: 1em;
`;

const Copy = styled.div`
  padding: 0 18px;
`;

export default ({content}) => (
  <Wrapper>
    {content.edges.map((b, k) => (
      <Box key={k}>
        <Image src={b.node.image.url} />
        <H3>{b.node.title}</H3>
        <Copy
          dangerouslySetInnerHTML={{
            __html: b.node.copyNode.childMarkdownRemark.html,
          }}
        />
      </Box>
    ))}
  </Wrapper>
);
