import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import b1 from './broadband-bulleticon1.png';
import b2 from './broadband-bulleticon2.png';
import b3 from './broadband-bulleticon3.png';
import b4 from './broadband-bulleticon4.png';
import b5 from './broadband-bulleticon5.png';
import b6 from './broadband-bulleticon6.png';

const Bullets = styled.ul`
  list-style: none;
  padding-left: 0;

  width: 300px;
  margin: 0 auto;

  @media (min-width: 400px) {
    width: 370px;
  }
  @media (min-width: 960px) {
    columns: 2;
    width: auto;
    width: auto;
    max-width: 800px;
  }
  @media (min-width: 1000px) {
    max-width: 960px;
  }
`;
const Bullet = styled.li`
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 15px;
  padding-left: 44px;
  position: relative;

  @media (min-width: 960px) {
  }

  img {
    position: absolute;
    width: 25px;
    left: 0;
  }

  @media (min-width: 640px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 36px;
    padding-left: 60px;
    margin-bottom: 18px;

    img {
      width: 36px;
    }
  }

  // 25/30
`;

export default ({content}) => (
  <Bullets>
    <Bullet>
      <img src={b1} />

      {content.bullet1}
    </Bullet>
    <Bullet>
      <img src={b2} />

      {content.bullet2}
    </Bullet>
    <Bullet>
      <img src={b3} />

      {content.bullet3}
    </Bullet>
    <Bullet>
      <img src={b4} />

      {content.bullet4}
    </Bullet>
    <Bullet>
      <img src={b5} />

      {content.bullet5}
    </Bullet>
    <Bullet>
      <img src={b6} />

      {content.bullet6}
    </Bullet>
  </Bullets>
);
